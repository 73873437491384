// #region Global Imports
import * as React from 'react';
import NextImage from 'next/image';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import type { LayoutContextValue } from '@contexts/LayoutContext';
import { LayoutContext } from '@contexts/LayoutContext';
import logo from './img/treu-suisse.svg';
import styles from './Footer.module.sass';
// #endregion Local Imports

export const Footer: React.FunctionComponent = () => {
  return (
    <div className={styles.Footer__root}>
      <div className={cx(styles.Footer__item, styles.Footer__item_logo)}>
        <a
          href="https://www.treuhandsuisse.ch"
          rel="noreferrer"
          target="_blank"
        >
          <NextImage
            alt="logo Treuhand Suisse"
            height={132}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            src={logo}
            width={270}
          />
        </a>
      </div>
      <LayoutContext.Consumer>
        {({
          footer,
        }: LayoutContextValue):
          | React.ReactElement<
              unknown,
              string | React.JSXElementConstructor<unknown>
            >[]
          | undefined => {
          return footer?.translation?.map((item, index): React.ReactElement => {
            if (item?.value?.__typename === 'BlockContentFooter') {
              return (
                <div className={styles.Footer__item} key={item.value.id}>
                  <div className={styles.Footer__text}>
                    <div className={cx(styles.Footer__title, 'h1-small')}>
                      {item.value.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.value.text?.processed ?? '',
                      }}
                    />
                  </div>
                </div>
              );
            }
            return <React.Fragment key={index} />;
          });
        }}
      </LayoutContext.Consumer>
    </div>
  );
};
